import React from "react";
import ReactMarkdown from "react-markdown";
import * as RemarkMathPlugin from "remark-math";
import CodeBlock from "./CodeBlock";
import ShortCodeRenderer from "./ShortCodeRenderer";
import { InlineMathRenderer, MathRenderer } from "./LatexRenderer";

function MarkdownRenderer(
  props: ReactMarkdown.ReactMarkdownProps
): JSX.Element {
  return (
    <ReactMarkdown
      source={props.source}
      skipHtml={false}
      escapeHtml={false}
      linkTarget="_blank"
      plugins={[RemarkMathPlugin, require("remark-shortcodes")]}
      renderers={{
        code: CodeBlock,
        math: MathRenderer,
        inlineMath: InlineMathRenderer,
        shortcode: ShortCodeRenderer
      }}
      className="content page"
    />
  );
}

export default MarkdownRenderer;

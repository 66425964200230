import React, { useState, useEffect } from "react";
import { get } from "../../../API/fetcher";
import MarkdownRenderer from "../../MarkdownRenderer/MarkdownRenderer";
import IPostModel from "../../../API/Models/IPostModel";

export interface IDynamicPageProps {
  title: string;
}

function DynamicPage(props: IDynamicPageProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({} as IPostModel);

  useEffect(() => {
    setIsLoading(true);

    async function getPosts() {
      const post = await get<IPostModel>(`/posts/${props.title}`);

      if (post) setState(post);

      setIsLoading(false);
    }

    getPosts();
  }, [props.title]);

  return isLoading ? (
    <div>loading</div>
  ) : (
    <div>
      <MarkdownRenderer source={state.content} />
    </div>
  );
}

export default DynamicPage;

import React from "react";
import SocialIcon, { SocialIcons } from "../../SocialIcon/SocialIcon";

const LandingSocialBar = (): JSX.Element => {
  return (
    <div className="Landing-social page">
      <SocialIcon
        href="https://twitter.com/baalansellers"
        icon={SocialIcons.Twitter}
      />
      <SocialIcon
        href="https://facebook.com/baalansellers"
        icon={SocialIcons.Facebook}
      />
      <SocialIcon
        href="https://www.linkedin.com/in/jamesasellers"
        icon={SocialIcons.LinkedIn}
      />
      <SocialIcon
        href="http://github.com/baalansellers"
        icon={SocialIcons.GitHub}
      />
      <SocialIcon
        href="http://us.playstation.com/publictrophy/index.htm?onlinename=HertyMcLoven"
        icon={SocialIcons.PlayStation}
      />
      <SocialIcon
        href="http://live.xbox.com/Profile?GamerTag=HertyMcLoven"
        icon={SocialIcons.XBox}
      />
      <SocialIcon
        href="http://steamcommunity.com/id/baalansellers/"
        icon={SocialIcons.Steam}
      />
    </div>
  );
};

export default LandingSocialBar;

import React from "react";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Github } from "./github.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";
import { ReactComponent as Playstation } from "./playstation.svg";
import { ReactComponent as Steam } from "./steam.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Xbox } from "./xbox.svg";

export enum SocialIcons {
  Facebook = 'Facebook',
  GitHub = 'GitHub',
  LinkedIn = 'LinkedIn',
  PlayStation = 'PlayStation',
  Steam = 'Steam',
  Twitter = 'Twitter',
  XBox = 'XBox'
}

function getImage(icon: SocialIcons): JSX.Element {
  switch (icon) {
    case SocialIcons.Facebook:
      return <Facebook />;
    case SocialIcons.GitHub:
      return <Github />;
    case SocialIcons.LinkedIn:
      return <Linkedin />;
    case SocialIcons.PlayStation:
      return <Playstation />;
    case SocialIcons.Steam:
      return <Steam />;
    case SocialIcons.Twitter:
      return <Twitter />;
    case SocialIcons.XBox:
      return <Xbox />;
  }
}

export interface ISocialIconProps {
  href: string;
  icon: SocialIcons;
}

function SocialIcon(props: ISocialIconProps): JSX.Element {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer" role="navigation" aria-label={`${props.icon} profile link`}>
      {getImage(props.icon)}
    </a>
  );
}

export default SocialIcon;

import React, { useState, useEffect, useCallback } from "react";
import detectPassiveEvents from "detect-passive-events";
//@ts-ignore
import TweenFunctions from "tween-functions";

import "./backToTopButton.css";

const SHOW_AT_POSITION = 150;
const STOP_AT_POSITION = 0;
const ANIMATION_DURATION = 500;
const EASING_TYPE = "easeOutCubic";

const MainStyle: React.CSSProperties = {
  height: 50,
  position: "fixed",
  bottom: 60,
  width: 50,
  WebkitTransition: "all 0.5s ease-in-out",
  transition: "all 0.5s ease-in-out",
  transitionProperty: "opacity, right",
  cursor: "pointer",
  opacity: 0,
  right: -50,
  zIndex: 1000
};

const ToggledStyle: React.CSSProperties = {
  opacity: 1,
  right: "calc(0.1em + 2.2vw)"
};

interface IAnimation {
  StartPosition: number;
  CurrentAnimationTime: number;
  StartTime: number | null;
  AnimationFrame: number | null;
}

interface BackToTopButtonProps {
  children?: JSX.Element;
}

const BackToTopButton = (props: BackToTopButtonProps): JSX.Element => {
  const [showButton, setShowButton] = useState(false);

  let Animation: IAnimation = {
    StartPosition: 0,
    CurrentAnimationTime: 0,
    StartTime: null,
    AnimationFrame: null
  };

  const HandleScroll = () => {
    if (window.pageYOffset > SHOW_AT_POSITION) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const ScrollingFrame = () => {
    const timestamp = Math.floor(Date.now());

    // If StartTime has not been assigned a value, assign it the start timestamp.
    if (!Animation.StartTime) {
      Animation.StartTime = timestamp;
    }

    // set CurrentAnimationTime every iteration of ScrollingFrame()
    Animation.CurrentAnimationTime = timestamp - Animation.StartTime;

    // if we hit the StopPosition, StopScrollingFrame()
    if (window.pageYOffset <= STOP_AT_POSITION) {
      StopScrollingFrame();
    } else {
      // Otherwise continue ScrollingFrame to the StopPosition.
      // Does not support horizontal ScrollingFrame.
      // Let TweenFunctions handle the math to give us a new position based on AnimationDuration and EasingType type
      let YPos = TweenFunctions[EASING_TYPE](
        Animation.CurrentAnimationTime,
        Animation.StartPosition,
        STOP_AT_POSITION,
        ANIMATION_DURATION
      );

      if (YPos <= STOP_AT_POSITION) {
        YPos = STOP_AT_POSITION;
      }

      window.scrollTo(0, YPos);

      // Request another frame to be painted
      Animation.AnimationFrame = window.requestAnimationFrame(ScrollingFrame);
    }
  };

  const StopScrollingFrame = useCallback(() => {
    // Stop the Animation Frames.
    if (Animation.AnimationFrame) {
      window.cancelAnimationFrame(Animation.AnimationFrame);
    }
  }, [Animation.AnimationFrame]);

  const HandleClick = () => {
    // Scroll to StopPosition
    StopScrollingFrame(); // Stoping all AnimationFrames
    Animation.StartPosition = window.pageYOffset; // current scroll position
    Animation.CurrentAnimationTime = 0;
    Animation.StartTime = null;
    // Start the scrolling animation.
    Animation.AnimationFrame = window.requestAnimationFrame(ScrollingFrame);
  };

  useEffect(() => {
    HandleScroll(); // run at mount incase we are already scrolled down
    window.addEventListener("scroll", HandleScroll);

    window.addEventListener(
      "wheel",
      StopScrollingFrame,
      detectPassiveEvents.hasSupport ? { passive: true } : false
    ); // Stop animation if user mouse wheels during animation.

    window.addEventListener(
      "touchstart",
      StopScrollingFrame,
      detectPassiveEvents.hasSupport ? { passive: true } : false
    ); // Stop animation if user touches the screen during animation.

    return () => {
      window.removeEventListener("wheel", StopScrollingFrame, false);
      window.removeEventListener("touchstart", StopScrollingFrame, false);
    };
  }, [StopScrollingFrame]);

  return (
    <aside
      role="button"
      aria-label="Scroll to top of page"
      tabIndex={showButton ? 0 : -1}
      className={`back-to-top-button${
        showButton ? " back-to-top-button-toggled" : ""
      }`}
      style={{
        ...MainStyle,
        ...(showButton && ToggledStyle)
      }}
      onClick={HandleClick}
      onKeyPress={HandleClick}
    >
      {props.children ? props.children : <></>}
    </aside>
  );
};

export default BackToTopButton;

import React, { useState, useEffect } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import MdEditor from "react-markdown-editor-lite";
import MarkdownRenderer from "../MarkdownRenderer/MarkdownRenderer";
import { get, put, post } from "../../API/fetcher";
import IPostModel from "../../API/Models/IPostModel";
import ISignedS3Model from "../../API/Models/ISignedS3Model";
import "./Editor.css";
import "react-markdown-editor-lite/lib/index.css";

const clientId: string = `${process.env.REACT_APP_AUTH_CLIENT_ID}`;

interface IPostTitleModel {
  title: string;
}

const emptyPostTitle: IPostTitleModel[] = [];

interface IPostPickerProps {
  onChange: (title: string) => void;
}

function PostPicker(props: IPostPickerProps): JSX.Element {
  const [posts, setPosts] = useState(emptyPostTitle);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    get<IPostTitleModel[]>("/posts").then((titles) => {
      setPosts(titles);
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    props.onChange(e.target.value);
  };

  return (
    <select value={selectedValue} onChange={handleChange}>
      <option value="">-- Select One --</option>
      {posts.map(
        (obj, idx): JSX.Element => (
          <option key={idx} value={obj.title}>
            {obj.title}
          </option>
        )
      )}
    </select>
  );
}

const responseGoogle = (
  response: GoogleLoginResponse | GoogleLoginResponseOffline
) => {
  console.log(response);
};

const Editor = (): JSX.Element => {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [pickerValue, setPickerValue] = useState("");
  const [markdown, setMarkdown] = useState("");
  const [token, setToken] = useState("");

  const handlePickerChange = (title: string) => {
    setPickerValue(title);
  };

  const handleEditorChange = (data: { html: string; text: string }): void => {
    setContent(data.text);
  };

  const handleLoginSuccess = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    setToken((response as GoogleLoginResponse).tokenId);
  };

  const handleSaveClick = async (): Promise<void> => {
    const payload: IPostModel = { title: title, content: content };
    const response = await put(`/posts/${title}`, payload, token);

    if (response.status !== 200) {
      console.log(response);
      setMessage(`Error: ${response.statusText}`);
    } else {
      setMessage("save successful");
    }
  };

  const handleLoadClick = async (): Promise<void> => {
    if (pickerValue !== "") {
      const response = await get<IPostModel>(`/posts/${pickerValue}`);

      if (!response) {
        setMessage(`Error: Post could not be retrieved`);
      }

      setTitle(response.title);
      setMarkdown(response.content);
      setMessage("load successful");
    }
  };

  const handleFileUpload = (file: File, callback: (url: string) => void) => {
    let fileParts = file.name.split(".");
    let fileName = fileParts[0];
    let fileType = fileParts[1];

    post("/sign_s3", { fileName: fileName, fileType: fileType }).then(
      async (rawRes) => {
        var response: ISignedS3Model = await rawRes.json();
        var signedRequest = response.signedRequest
          ? response.signedRequest
          : "";
        var url = response.url ? response.url : "";

        var options = {
          method: "put",
          body: file,
          headers: {
            "Content-Type": fileType,
          },
        };
        fetch(signedRequest, options)
          .then(() => {
            console.log(url);
            callback(url);
          })
          .catch((error) => {
            alert("ERROR " + JSON.stringify(error));
          });
      }
    );
  };

  return (
    <div className="box">
      <div className="row header">
        <PostPicker onChange={handlePickerChange} />
        <button onClick={handleLoadClick}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input type="button" value="Save" onClick={handleSaveClick} />
        <div>Message: {message}</div>
        <div>
          <GoogleLogin
            clientId={clientId}
            buttonText="Login"
            onSuccess={handleLoginSuccess}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </div>
      <div className="row content">
        <MdEditor
          name="textarea"
          value={markdown}
          renderHTML={(text) =>
            ReactDOMServer.renderToString(<MarkdownRenderer source={text} />)
          }
          onChange={handleEditorChange}
          onImageUpload={handleFileUpload}
          style={{ height: "100%" }}
        />
      </div>
    </div>
  );
};

export default Editor;

import React, { CSSProperties } from "react";
import ReactPlayer from "react-player";
import { FloatProperty } from "csstype";

const ValidateFloatValue = (value: string): FloatProperty => {
  if (value === "left" || value === "right" || value === "none") {
    return value as FloatProperty;
  }
  return "none";
};

const GetImageFloatStyle = (value: string): CSSProperties => {
  switch (value) {
    case "center":
      return { marginLeft: "auto", marginRight: "auto" };
    default:
      return {
        float: ValidateFloatValue(value),
        marginLeft: value === "left" ? 0 : "2.5%",
        marginRight: value === "right" ? 0 : "2.5%",
        marginTop: "2.5%",
        marginBottom: "2.5%"
      };
  }
};

const ShortCodeRenderer = (props: {
  identifier: string;
  attributes: { [key: string]: string };
}): JSX.Element => {
  const attrKeys = Object.keys(props.attributes);

  switch (props.identifier) {
    case "video": {
      if (props.attributes["url"]) {
        return (
          <ReactPlayer
            url={props.attributes["url"]}
            controls={true}
            className="player"
            width="25vw"
            height="14.0625vw"
          />
        );
      }
      break;
    }
    case "image": {
      if (props.attributes["url"] && props.attributes["float"]) {
        return (
          <div
            className={
              props.attributes["header"]
                ? "image-header-container"
                : "image-container"
            }
            style={GetImageFloatStyle(props.attributes["float"])}
          >
            <img
              className="encapsulated-image"
              src={props.attributes["url"]}
              alt={props.attributes["title"]}
            />
            <figcaption className="image-caption">
              {props.attributes["caption"]}
            </figcaption>
          </div>
        );
      }
      break;
    }
    default: {
      return (
        <div>
          shortcode: {props.identifier}
          <ul>
            {attrKeys.map((key, idx) => {
              return (
                <li key={idx}>
                  {key}: {props.attributes[key]}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  }

  return <></>;
};

export default ShortCodeRenderer;

import React from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import MenuDropDown from "../../MenuDropDown/MenuDropDown";
import DynamicPage from "../../pages/DynamicPage/DynamicPage";
import Home from "../../pages/Home/Home";
import Editor from "../../Editor/Editor";

const LearnCWLink = (): JSX.Element => {
  window.location.href = "https://cw.alan.codes";
  return <></>;
};

const LandingNav = (): JSX.Element => {
  const location = useLocation();

  return (
    <div className="content-container">
      <nav className="Landing-menu separator">
        <ul>
          <li>
            <Link to="/" className={location.pathname === "/" ? "active" : ""}>
              Home
            </Link>
          </li>
          <li>
            <MenuDropDown
              title={"Coding"}
              items={[
                {
                  title: "The Button - Websockets",
                  path: "/thebuttonwebsockets"
                },
                {
                  title: "Learn CW Game - Code Dive",
                  path: "/learncwcode"
                },
                {
                  title: "alan.codes - React",
                  path: "/alancodesreact"
                }
              ]}
            />
          </li>
          <li>
            <MenuDropDown
              title="Electronics"
              items={[
                { title: "Dr Who Pumpkin", path: "/drwhopumpkin" },
                { title: "Navi Wings", path: "/naviwings" },
                { title: "Slack Orb Notifier", path: "/slackorbnotifier" },
                {
                  title: "Standing Desk Workstation",
                  path: "/standingdeskworkstation"
                },
                { title: "The Button", path: "/thebutton" },
                { title: "Wii Mouse Droids", path: "/wiimousedroids" }
              ]}
            />
          </li>
          <li>
            <MenuDropDown
              title={"Robotics"}
              items={[
                { title: "Donkey Car", path: "/donkeycar" },
                { title: "Drum Bot", path: "/drumbot" }
              ]}
            />
          </li>
          <li>
            <MenuDropDown
              title={"Ham Radio"}
              items={[
                {
                  title: "Toothbrush Holder Dipole",
                  path: "/toothbrushholderdipole"
                },
                {
                  title: "Learn CW Game",
                  path: "/learn-cw"
                }
              ]}
            />
          </li>
        </ul>
      </nav>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/thebutton">
          <DynamicPage title="theButton" />
        </Route>
        <Route path="/projects/the-button-ajax-raspberry-pi">
          <DynamicPage title="theButton" />
        </Route>
        <Route path="/thebuttonwebsockets">
          <DynamicPage title="theButton2" />
        </Route>
        <Route path="/projects/the-button-websocket-raspberry-pi">
          <DynamicPage title="theButton2" />
        </Route>
        <Route path="/wiimousedroids">
          <DynamicPage title="wiiMouseDroids" />
        </Route>
        <Route path="/projects/wii-mouse-droids">
          <DynamicPage title="wiiMouseDroids" />
        </Route>
        <Route path="/slackorbnotifier">
          <DynamicPage title="slackOrbNotifier" />
        </Route>
        <Route path="/projects/slack-orb-notifier">
          <DynamicPage title="slackOrbNotifier" />
        </Route>
        <Route path="/toothbrushholderdipole">
          <DynamicPage title="toothbrushHolderDipole" />
        </Route>
        <Route path="/projects/hf-dipole-antenna">
          <DynamicPage title="toothbrushHolderDipole" />
        </Route>
        <Route path="/standingdeskworkstation">
          <DynamicPage title="standingDeskWorkstation" />
        </Route>
        <Route path="/projects/diy-standing-desk-workstation">
          <DynamicPage title="standingDeskWorkstation" />
        </Route>
        <Route path="/drwhopumpkin">
          <DynamicPage title="drWhoPumpkin" />
        </Route>
        <Route path="/projects/doctor-who-pumpkin-pulsing-led">
          <DynamicPage title="drWhoPumpkin" />
        </Route>
        <Route path="/naviwings">
          <DynamicPage title="naviWings" />
        </Route>
        <Route path="/projects/last-minute-el-wire-navi-wings">
          <DynamicPage title="naviWings" />
        </Route>
        <Route path="/donkeycar">
          <DynamicPage title="donkeyCar" />
        </Route>
        <Route path="/learn-cw">
          <LearnCWLink />
        </Route>
        <Route path="/drumbot">
          <DynamicPage title="drumBot" />
        </Route>
        <Route path="/learncwcode">
          <DynamicPage title="learnCWCode" />
        </Route>
        <Route path="/alancodesreact">
          <DynamicPage title="alanCodesReact" />
        </Route>
        <Route path="/mod">
          <Editor />
        </Route>
      </Switch>
    </div>
  );
};

export default LandingNav;

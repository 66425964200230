import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRev } from "@fortawesome/free-brands-svg-icons";
import LandingFeatures from "../../Landing/LandingFeatures/LandingFeatures";

function Home(): JSX.Element {
  return (
    <div className="content">
      <LandingFeatures />
      <p>
        Hello, my name is Alan Sellers. I live and work in the Atlanta area. I
        currently work for{" "}
        <a href="https://rev.io" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faRev} style={{ fontSize: "1.2em" }} />{" "}Rev.io
        </a>{" "}
        as a senior software engineer. Building is my passion, whether it’s
        something physical (hardware) or digital (software). I always have some
        sort of side project going, so this website is dedicated to chronicling
        things that I build in my spare time, primarily for my own reference,
        but also in the hopes that something I’ve done will help others in
        projects of their own.
      </p>
    </div>
  );
}

export default Home;

import React from "react";
import { BlockMath, InlineMath } from "react-katex";

import "katex/dist/katex.min.css";

export const MathRenderer = (props: { value: string }) => (
  <BlockMath>{props.value}</BlockMath>
);

export const InlineMathRenderer = (props: { value: string }) => (
  <InlineMath>{props.value}</InlineMath>
);

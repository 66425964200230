import React from "react";
import { Link } from "react-router-dom";

const LandingFeatures = (): JSX.Element => {
  return (
    <div className="feature-frame">
      <div className="feature-slot">
        <Link to="/thebutton">
          <div className="circular theButton"></div>
        </Link>
        <Link to="/thebutton">The Button</Link>
      </div>
      <div className="feature-slot">
        <Link to="/wiimousedroids">
          <div className="circular mouseDroid"></div>
        </Link>
        <Link to="/wiimousedroids">Mouse Droid</Link>
      </div>
      <div className="feature-slot">
        <Link to="/slackorbnotifier">
          <div className="circular orbNotifier"></div>
        </Link>
        <Link to="/slackorbnotifier">Slack Orb Notifier</Link>
      </div>
      <div className="feature-slot">
        <Link to="/drumbot">
          <div className="circular drumBot"></div>
        </Link>
        <Link to="/drumbot">Drum Bot</Link>
      </div>
    </div>
  );
};

export default LandingFeatures;

import React from "react";

import logo from "./alan-cartoon-square.svg";

const LandingBadge = (): JSX.Element => {
  return (
    <div className="Landing-header page">
      <img src={logo} className="Landing-logo" alt="logo" />
      <h1 className="Landing-title">Alan Sellers</h1>
      <h2 className="Landing-subtitle">
        a record of my tech and gadget making adventures
      </h2>
    </div>
  );
};

export default LandingBadge;

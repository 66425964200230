const API_URL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_HOST}`
    : "";

export const get = <T,>(request: RequestInfo): Promise<T> => {
  request = `${API_URL}${request}`;
  return new Promise((resolve) => {
    fetch(request)
      .then((response) => response.json())
      .then((body) => {
        resolve(body);
      });
  });
};

export const put = async (
  request: RequestInfo,
  payload: any,
  authToken?: string
): Promise<Response> => {
  request = `${API_URL}${request}`;
  let headers = new Headers({ "Content-Type": "application/json" });

  if (authToken) {
    headers.append('X-Goog-JWT-ID', authToken);
  }

  return new Promise((resolve) => {
    fetch(request, {
      method: "put",
      body: JSON.stringify(payload),
      headers: headers,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((reason) => {
        console.log(reason);
      });
  });
};

export const post = async (
  request: RequestInfo,
  payload: any,
  authToken?: string
): Promise<Response> => {
  request = `${API_URL}${request}`;
  return new Promise((resolve) => {
    fetch(request, {
      method: "post",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((reason) => {
        console.log(reason);
      });
  });
};

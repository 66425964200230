import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import LandingNav from "./LandingNav/LandingNav";
import LandingSocialBar from "./LandingSocialBar/LandingSocialBar";
import LandingBadge from "./LandingBadge/LandingBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleUp } from "@fortawesome/pro-duotone-svg-icons";
import BackToTopButton from "../BackToTopButton/BackToTopButton";

import "./Landing.css";

const Landing: React.FC = () => {
  return (
    <Router>
      <div className="Landing">
        <LandingBadge />
        <LandingSocialBar />
        <LandingNav />
        <div className="Landing-footer">
          <div className="Landing-social-container">
            <LandingSocialBar />
          </div>
        </div>
        <BackToTopButton>
          <FontAwesomeIcon icon={faChevronCircleUp} />
        </BackToTopButton>
      </div>
    </Router>
  );
};

export default Landing;

import React, { useState, useRef, useEffect } from "react";
import styles from "./menuDropDown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretCircleDown,
  faCaretCircleUp
} from "@fortawesome/pro-duotone-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import { some } from 'lodash';

export interface IMenuDropDownProps {
  title: string;
  items: IMenuDropDownItem[];
}

export interface IMenuDropDownItem {
  path?: string;
  title: string;
}

const MenuDropDown = (props: IMenuDropDownProps): JSX.Element => {
  const [showMenu, setShowMenu] = useState(false);
  const nodeRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const highlightParent = some(props.items, ["path", location.pathname]);

  const handleClick = () => {
    setShowMenu(!showMenu);
  };

  const handleOutsideClick = (e: MouseEvent): void => {
    if (nodeRef.current && nodeRef.current.contains(e.target as Node)) {
      //do nothing for now
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="menu-item-container" ref={nodeRef}>
      <div className={`menu-item-title ${highlightParent ? "active" : ""}`} onClick={handleClick}>
        {props.title}
        <FontAwesomeIcon
          icon={showMenu ? faCaretCircleUp : faCaretCircleDown}
          className={styles.menuIcon}
        />
      </div>
      {showMenu && (
        <ul className={styles.menuItemList}>
          {props.items.map(
            (item: IMenuDropDownItem, idx: number): JSX.Element => {
              return (
                <li className={styles.menuItemListItem} key={idx}>
                  {item.path && <NavLink to={item.path} onClick={handleClick}>{item.title}</NavLink>}
                  {!item.path && <span onClick={handleClick}>{item.title}</span>}
                </li>
              );
            }
          )}
        </ul>
      )}
    </div>
  );
};

export default MenuDropDown;

import React from "react";
import Highlight from "react-highlight.js";

export interface ICodeBlockProps {
  value: string;
  language: string;
}

function CodeBlock(props: ICodeBlockProps): JSX.Element {
  return (
    <pre>
      <code className={`language-${props.language}`}>
        <Highlight language={props.language}>{props.value}</Highlight>
      </code>
    </pre>
  );
}

export default CodeBlock;
